// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestarticles-js": () => import("./../../../src/pages/bestarticles.js" /* webpackChunkName: "component---src-pages-bestarticles-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-goto-js": () => import("./../../../src/pages/goto.js" /* webpackChunkName: "component---src-pages-goto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-top-10-dup-js": () => import("./../../../src/pages/top10dup.js" /* webpackChunkName: "component---src-pages-top-10-dup-js" */),
  "component---src-pages-web-host-reviews-js": () => import("./../../../src/pages/web-host-reviews.js" /* webpackChunkName: "component---src-pages-web-host-reviews-js" */),
  "component---src-pages-writeforus-js": () => import("./../../../src/pages/writeforus.js" /* webpackChunkName: "component---src-pages-writeforus-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-review-js": () => import("./../../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-wc-category-js": () => import("./../../../src/templates/wcCategory.js" /* webpackChunkName: "component---src-templates-wc-category-js" */)
}

